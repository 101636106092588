/* =============================================== */
// Disable right click to prevent images from being saved
/* =============================================== */
function clickIE() {
  if (document.all) {
    return false;
  }
}
function clickNS(e) {
  if (document.layers || (document.getElementById && !document.all)) {
    if (e.which == 2 || e.which == 3) {
      return false;
    }
  }
}
if (document.layers) {
  document.captureEvents(Event.MOUSEDOWN);
  document.onmousedown = clickNS;
} else {
  document.onmouseup = clickNS;
  document.oncontextmenu = clickIE;
}
document.oncontextmenu = new Function("return false");
document.getElementsByClassName("my-img").ondragstart = function () {
  return false;
};

// ドラッグ禁止
document.ondragstart = function () {
  return false;
};

/* =============================================== */
// Get 100vh
/* =============================================== */
function setHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}
setHeight();
window.addEventListener("resize", setHeight);

/* =============================================== */
// Slider
/* =============================================== */

import Swiper from "swiper/bundle";

/* ========= Vertical Slider ========= */
const verticalSlider = new Swiper(".vertical-slider", {
  direction: "vertical",
  effect: "slide",
  slidesPerView: 1,
  slideActiveClass: "vertical-slider__slide--active",
  speed: 700,
  resistance: false,
  touchStartPreventDefault: false,
  mousewheel: {
    forceToAxis: true,
  },
});
const verticalSliderNextBtn = document.querySelector(".vertical-slider__next-btn");
const verticalSliderPrevBtn = document.querySelector(".vertical-slider__prev-btn");
verticalSliderNextBtn.addEventListener("click", () => {
  verticalSlider.slideNext();
});
verticalSliderPrevBtn.addEventListener("click", () => {
  verticalSlider.slidePrev();
});

/* ========= Main Slider ========= */
const mainSlides = document.querySelectorAll(".main-slider__slide");

// カウンターの数字を全て取得
const allCounterNum = document.querySelectorAll(".counter__num");

// メインスライダーを取得
const mainSliderEl = document.querySelector(".main-slider");
// 背景色を設定
const bgColors = {
  summary: "#625663",
  cast: "#347195",
  ep1: "#95a59d",
  ep2: "#36566b",
  ep3: "#90714b",
  ep4: "#7fb0b6",
  ep5: "#657066",
  ep6: "#aa4d4b",
  ep7: "#274c50",
  ep8: "#6b9fba",
};

const mainSlider = new Swiper(".main-slider", {
  direction: "horizontal",
  loop: true,
  loopedSlides: mainSlides.length,
  slideActiveClass: "slide-active",
  speed: 700,
  preventClicks: false,
  preventClicksPropagation: false,
  touchStartPreventDefault: false,
  spaceBetween: 20,
  mousewheel: {
    forceToAxis: true,
  },
  on: {
    slideChange: function () {
      let num = this.realIndex;

      allCounterNum.forEach((counterNum) => {
        if (counterNum.classList.contains("counter__num--active")) {
          counterNum.classList.remove("counter__num--active");
        }
      });

      if (num === 0) {
        document.querySelector(".counter__num--0").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.summary;
      } else if (num === 1) {
        document.querySelector(".counter__num--05").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.cast;
      } else if (num === 2) {
        document.querySelector(".counter__num--1").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep1;
      } else if (num === 3) {
        document.querySelector(".counter__num--2").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep2;
      } else if (num === 4) {
        document.querySelector(".counter__num--3").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep3;
      } else if (num === 5) {
        document.querySelector(".counter__num--4").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep4;
      } else if (num === 6) {
        document.querySelector(".counter__num--5").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep5;
      } else if (num === 7) {
        document.querySelector(".counter__num--6").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep6;
      } else if (num === 8) {
        document.querySelector(".counter__num--7").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep7;
      } else if (num === 9) {
        document.querySelector(".counter__num--8").classList.add("counter__num--active");
        mainSliderEl.style.backgroundColor = bgColors.ep8;
      }
    },
  },
});

/* ========= Button Slider ========= */
// ボタンスライドを全て取得
const btnSlides = document.querySelectorAll(".btn-slider__slide");

const btnSlider = new Swiper(".btn-slider", {
  controller: {
    control: mainSlider,
    inverse: false,
    by: "slide",
  },
  direction: "horizontal",
  slideToClickedSlide: true,
  centeredSlides: true,
  slidesPerView: 2,
  loop: true,
  loopedSlides: mainSlides.length,
  slideActiveClass: "btn-slider__slide--active",
  speed: 700,
  slideDuplicateActiveClass: "btn-slider__duplicate-slide--active",
});

mainSlider.controller.control = btnSlider;

/* =============================================== */
// Modal - Thanks
/* =============================================== */
const modalThanksBtn = document.getElementById("modal-thanks-btn");
const modalThanks = document.getElementById("modal-thanks");
const modalThanksClose = document.getElementById("modal-thanks-close");

modalThanksBtn.addEventListener("click", () => {
  modalThanks.classList.add("modal-thanks--is-active");
});

modalThanksClose.addEventListener("click", () => {
  modalThanks.classList.remove("modal-thanks--is-active");
});

/* =============================================== */
// Modal - Cast
/* =============================================== */

// CASTモーダルを取得
const modalCast01 = document.getElementById("modal-cast-01");
const modalCast02 = document.getElementById("modal-cast-02");

// CASTモーダルの×ボタン
const modalCastCloseBtns = document.querySelectorAll(".modal-cast__close");
modalCastCloseBtns.forEach((modalCastCloseBtn) => {
  modalCastCloseBtn.addEventListener("click", () => {
    modalCastCloseBtn.parentElement.classList.remove("modal-cast--is-active");
  });
});

// CASTモーダルを全て取得
const allModalCast = document.querySelectorAll(".modal-cast");

allModalCast.forEach((modalCast) => {
  modalCast.addEventListener("click", (e) => {
    if (!e.target.classList.contains("modal-cast__content")) {
      const activeModal = document.querySelector(".modal-cast--is-active");
      activeModal.classList.remove("modal-cast--is-active");
    }
  });
});

/* =============================================== */
// Modal - EP
/* =============================================== */

// EPのモーダルを個々に取得
const modalEp01 = document.getElementById("modal-ep-01");
const modalEp02 = document.getElementById("modal-ep-02");
const modalEp03 = document.getElementById("modal-ep-03");
const modalEp04 = document.getElementById("modal-ep-04");
const modalEp05 = document.getElementById("modal-ep-05");
const modalEp06 = document.getElementById("modal-ep-06");
const modalEp07 = document.getElementById("modal-ep-07");
const modalEp08 = document.getElementById("modal-ep-08");

// EPのモーダルを全て取得
const modalEp = document.querySelectorAll(".modal-ep");
// EPのモーダルのコンテンツ以外をクリック時にモーダルを閉じる
modalEp.forEach((ep) => {
  ep.addEventListener("click", (e) => {
    if (!e.target.classList.contains("modal-ep__content")) {
      const activeModal = document.querySelector(".modal-ep--is-active");
      activeModal.classList.remove("modal-ep--is-active");
      setTimeout(() => {
        footerClone.style.display = "none";
      }, 600);
    }
  });
});

/* =============================================== */
// Modal button click event
/* =============================================== */

// モーダル表示時用のclone footer
const footerClone = document.querySelector(".footer--clone");
const showFooterClone = (el) => {
  el.style.display = "flex";
};

document.body.addEventListener("click", (e) => {
  // CAST 01のモーダルを開ける
  if (e.target.id === "modal-cast-btn-01") {
    modalCast01.classList.add("modal-cast--is-active");
  }

  // CAST 02のモーダルを開ける
  if (e.target.id === "modal-cast-btn-02") {
    modalCast02.classList.add("modal-cast--is-active");
  }

  // EP 01のモーダルを開ける
  if (e.target.parentElement.id === "modal-ep-btn-01") {
    if (modalEp01) {
      modalEp01.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }

  // EP 02のモーダルを開ける
  if (e.target.parentElement.id === "modal-ep-btn-02") {
    if (modalEp02) {
      modalEp02.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }

  // EP 03のモーダルを開ける
  if (e.target.parentElement.id === "modal-ep-btn-03") {
    if (modalEp03) {
      modalEp03.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }

  if (e.target.parentElement.id === "modal-ep-btn-04") {
    if (modalEp04) {
      modalEp04.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }

  if (e.target.parentElement.id === "modal-ep-btn-05") {
    if (modalEp05) {
      modalEp05.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }

  if (e.target.parentElement.id === "modal-ep-btn-06") {
    if (modalEp06) {
      modalEp06.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }

  if (e.target.parentElement.id === "modal-ep-btn-07") {
    if (modalEp07) {
      modalEp07.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }

  if (e.target.parentElement.id === "modal-ep-btn-08") {
    if (modalEp08) {
      modalEp08.classList.add("modal-ep--is-active");
      showFooterClone(footerClone);
    }
  }
});
